import { ResourceCategoryDto } from "./resourceCategory.dto";

export class ResourceDto {
  id: string;
  name: string;
  priority: number;
  abbreviation: string;
  active: boolean;
  adminOnly: boolean;
  deleted: boolean;
  steltronicNumber: number;
  tripleSeatRoomId: number | null;
  tripleSeatRoomPath: string;
  aboutGolfSimId: string;
  resourceCategory: ResourceCategoryDto;
  atomicGolfBayId: string;
}