import { Pricing, FeeType } from '../entities/enums';
import { Question } from './questionSetting.dto';
import { SchedulePackageSettingDto } from './schedulePackageSetting.dto';

export class CreatePackageSettingDto {
    name: string;
    prefix: string;
    description: string;
    shortDescription: string;
    tags: string;
    status: boolean;
    numberOfLanes: number;
    countLanesByGuest: boolean;
    maxGuestsPerLane?: number;
    method: Pricing;
    venueId: string;
    minGuests: number;
    maxGuests: number;
    enableDurationChoice: boolean;
    duration: number;
    durationInSlots: string;
    durationInSlotsForAdmin?: string;
    durationInSlotsForMember?: string;
    showPolicy: boolean;
    packagePolicyText: string;
    packagePolicyTitle: string;
    packagePolicyOverride: boolean;
    maxGuestsPerLaneForAdmin?: number;
    minGuestsForAdmin: number;
    maxGuestsForAdmin: number;
    adminCutOffTime?: number;
    image: string;
    deposit: number;
    depositType: string;
    leadTime: number;
    shiftingForReservationStart: number;
    daysForBooking: number;
    daysForBookingForAdmin?: number;
    schedulesPackage: SchedulePackageSettingDto[];
    taxForServiceCharge: string;
    serviceCharge: string;
    serviceChargeName: string;
    serviceChargeType: string;
    priority: number;
    parentSchedule: string | null;
    packageSpecificTripleSeatsIntegration: boolean;
    tripleSeatRoomId: number | null;
    tripleSeatLocationId: number | null;
    tripleSeatAccountId: number | null;
    tripleSeatContactId: number | null;
    tripleSeatEventTypeId: number | null;
    tripleSeatBlockedStatus?: string;
    tripleSeatPublishType?: string;
    tripleSeatStatusesOfPulledEvents?: string;
    activeCustomFields?: string;
    mandatoryCustomFields?: string;
    adminOnlyCustomFields?: string;
    tripleSeatCustomFieldsMapping?: string | null;
    tripleSeatCustomEventName?: string | null;
    tripleSeatReadAvailability: boolean;
    tripleSeatAvailabilityType?: string | null;
    tripleSeatAvailabilityFieldId?: string | null;
    adminOnly: boolean;
    allowCancellation: boolean;
    cancellationCutOff: number;
    cancellationFee: number;
    cancellationFeeType: FeeType;
    allowModification: boolean;
    modificationCutOff: number;
    modificationFee: number;
    modificationFeeType: FeeType;
    preBufferTimeForReservation: number;
    bufferTimeForReservation: number;
    enableAssignDuration: boolean;
    assignDuration: string;
    assignDurationForAdmin: string;
    syncWithSteltronic: boolean;
    revenueCategoriesMapping?: string | null;
    reservationTaxes: string;
    cardColor: string;
    textCardColor: string;
    enableAboutGolfIntegration: boolean;
    goTabProductId?: string | null;
    goTabSpotUuid?: string | null;
    packageSpecificSeatNinjasIntegration: boolean;
    splitSeatNinjaReservations: boolean;
    seatNinjaRestId?: string | null;
    seatNinjaPartyType?: string | null;
    seatninjaPartyFlags?: number | null;
    squareItemIds: string[];
    ageGroups?: string;
    eventsEmail: string;
    questions: Question[];
    enableGoTabIntegration: boolean;
}
export class PackageSettingDto {
    id: string;
    name: string;
    prefix: string;
    description: string;
    shortDescription: string;
    tags: string;
    status: boolean;
    numberOfLanes: number;
    countLanesByGuest: boolean;
    maxGuestsPerLane?: number;
    method: Pricing;
    venueId: string;
    minGuests: number;
    maxGuests: number;
    enableDurationChoice: boolean;
    duration: number;
    durationInSlots: string;
    durationInSlotsForAdmin?: string;
    durationInSlotsForMember?: string;
    showPolicy: boolean;
    packagePolicyText: string;
    packagePolicyTitle: string;
    packagePolicyOverride: boolean;
    maxGuestsPerLaneForAdmin?: number;
    minGuestsForAdmin: number;
    maxGuestsForAdmin: number;
    adminCutOffTime?: number;
    image: string;
    deposit: number;
    depositType: string;
    leadTime: number;
    shiftingForReservationStart: number;
    daysForBooking: number;
    daysForBookingForAdmin?: number;
    tenantId: string;
    schedulesPackage: SchedulePackageSettingDto[];
    taxForServiceCharge: string;
    serviceCharge: string;
    serviceChargeName: string;
    serviceChargeType: string;
    priority: number;
    parentSchedule: string | null;
    packageSpecificTripleSeatsIntegration: boolean;
    tripleSeatRoomId: number | null;
    tripleSeatLocationId: number | null;
    tripleSeatAccountId: number | null;
    tripleSeatContactId: number | null;
    tripleSeatEventTypeId: number | null;
    tripleSeatBlockedStatus?: string;
    tripleSeatPublishType?: string;
    tripleSeatStatusesOfPulledEvents?: string;
    activeCustomFields?: string;
    mandatoryCustomFields?: string;
    adminOnlyCustomFields?: string;
    tripleSeatCustomFieldsMapping?: string | null;
    tripleSeatCustomEventName?: string | null;
    tripleSeatReadAvailability: boolean;
    tripleSeatAvailabilityType?: string | null;
    tripleSeatAvailabilityFieldId?: string | null;
    adminOnly: boolean;
    allowCancellation: boolean;
    cancellationCutOff: number;
    cancellationFee: number;
    cancellationFeeType: FeeType;
    allowModification: boolean;
    modificationCutOff: number;
    modificationFee: number;
    modificationFeeType: FeeType;
    preBufferTimeForReservation: number;
    bufferTimeForReservation: number;
    enableAssignDuration: boolean;
    assignDuration: string;
    assignDurationForAdmin: string;
    isHidden: boolean;
    contactOnly: boolean;
    hidePackageDuration: boolean;
    hideInquiryRedirect: boolean;
    hidePrice: boolean;
    hidePriceOnTimeCard: boolean;
    syncWithSteltronic: boolean;
    revenueCategoriesMapping?: string | null;
    reservationTaxes: string;
    cardColor: string;
    textCardColor: string;
    enableAboutGolfIntegration: boolean;
    goTabProductId?: string | null;
    goTabSpotUuid?: string | null;
    packageSpecificSeatNinjasIntegration: boolean;
    splitSeatNinjaReservations: boolean;
    seatNinjaRestId?: string | null;
    seatNinjaPartyType?: string | null;
    seatninjaPartyFlags?: number | null;
    squareItemIds: string[];
    isMemberOnlyPackage: boolean;
    daysForBookingForMember?: number;
    memberLeadTime?: number;
    membershipPlanIds: string[];
    reservationDetails: string;
    selectTime: string;
    ageGroups?: string;
    eventsEmail: string;
    questions: Question[];
    enableGoTabIntegration: boolean;
}
