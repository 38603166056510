import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import dayjs from "dayjs";
import { orderBy, set } from "lodash";
import { nanoid } from "nanoid";
import {
  Toggle,
  DetailsList,
  SelectionMode,
  DetailsListLayoutMode,
  Icon,
  DetailsRow,
  IColumn,
  ActionButton,
  DefaultButton,
  IDropdownOption,
  Checkbox,
  IconButton,
} from "office-ui-fabric-react";
import {
  changeActiveCustomFields,
  getParentPackageScheduleAction,
  removeSchedulePackageAction,
  selectPackageAction,
  updatePackageAction,
  uploadPackageImageAction,
} from "../../../../actions/package-action";
import {
  selectCurrentPackageSchedule,
  selectIsImageLoadInProgress,
  selectIsLoadingPackage,
  selectPackage,
  selectPackages,
  selectParentPackageSchedule,
} from "../../../../reducers/packages";
import {
  selectCategories,
  selectSettingsState,
  selectTimeSlotDuration,
} from "../../../../reducers/settings";
import {
  selectGoTabProducts,
  selectGoTabSpots,
  selectSquare,
  selectVenue,
} from "../../../../reducers/venues";
import {
  State,
  SchedulePackageSettingDto,
  CreateSchedulePackageSettingDto,
  customFields,
  TripleSeatCustomReservationFields,
  TripleSeatAvailabilityType,
  InventoryMethodTypes,
  ParentScheduleDto,
  ReservationTaxType,
  FeeType,
} from "../../../../store/types";
import {
  getTime,
  formatScheduleDate,
  formatDuration,
  formatLabel,
  formatReadStatus,
  durationSlotsToTime,
  getBookingDuration,
  getTimeExtended,
} from "../../../../utils/formats";
import DeleteConfirmation from "../../../common/DeleteConfirmation/DeleteConfirmation";
import Panel from "../../../common/Panel";
import LinkButton from "../../../../../../common/components/LinkButton";
import ColorButton from "../../../../../../common/components/ColorButton";
import FormSelectField from "../../../common/FormSelectField";
import CalendarIcon from "../../../../assets/calendar-icon.svgr";
import VertIcon from "../../../../assets/more-vert.svgr";
import TrashIcon from "../../../../assets/trash-icon.svgr";
import { DEFAULT_CARD_COLOR, useViewport } from "../../../../hooks/responsive";
import EditSchedulePackage from "./EditSchedulePackage/EditSchedulePackage";
import CurrentSchedule from "../../../common/CurrentSchedule/CurrentSchedule";
import EditPackage from "./EditPackage/EditPackage";
import EditTripleSeatForPackage from "./EditTripleSeatForPackage/EditTripleSeatForPackage";
import EditPackageCancellation from "./EditPackageCancellation/EditPackageCancellation";
import EditAdminPackageReservation from "./EditAdminPackageReservation/EditAdminPackageReservation";
import Image from "../../../common/Image";
import FormTextField from "../../../../../../common/components/FormTextField";
import { cardColorTypeOptions, feeTypeOptions } from "../../VenueInfo";
import OpenIcon from "../../../../assets/open-in-new.svgr";
import { openTenantUrlWithPackageAction } from "../../../../actions/tenants-actions";
import AssignResource from "../../AssignResource/AssignResource";
import {
  getGoTabProductsAndSpotsForVenueAction,
  getSquareCatalogsAction,
} from "../../../../actions/venue-actions";
import EditSeatNinjaForPackage from "./EditSeatNinjaForPackage/EditSeatNinjaForPackage";
import { selectUIConfig } from "../../../../reducers/ui-reducer";
import "./packageDetails.scss";
import { activeCustomFieldsToUIModel } from "../../../../../../common/utils/formats";
import HtmlRenderer from "../../../../../../common/components/HTMLRenderer/HTMLRenderer";
import EditMembershipsSettings from "./EditMembershipSettings/EditMembershipsSettings";
import { selectPlans } from "../../../../reducers/membership";
import { getPlansAction } from "../../../../actions/membership-action";
import { parseAgeGroups } from "../../../../../../server/src/utils/ageGroups";
import { convertQuestionsToCustomFields } from "../../../../../../server/src/utils/package";
import { getQuestionsAction } from "../../../../actions/question-action";
import { selectQuestions } from "../../../../reducers/questions";
import FormHtmlTextField from "../../../FormHtmlTextField/FormHtmlTextField";

interface PackageItem {
  name: string;
  value: string;
}

interface PackageInfoItem {
  name: string;
  value: string;
  isHTML?: boolean;
}

interface AssignDuration {
  id: number;
  guests: string;
  duration: string;
}

interface CellData {
  id: string;
  start: string;
  end: string;
}

export interface CustomFieldsMapping {
  id: number;
  tsId: string;
  name: string;
  param?: string;
}

export interface RevenueCategoriesMapping {
  id: number;
  category: string;
  value: string;
}

interface PackageTagsMapping {
  id: number;
  tag: string;
  priority: number;
  available: boolean;
}

export const reservationFieldList = Object.entries(
  TripleSeatCustomReservationFields
).map((customField) => ({
  key: customField[0],
  text: customField[1],
}));

const scheduleListStyles = {
  focusZone: { cursor: "pointer" },
};

const durationOptions: IDropdownOption[] = Array(8)
  .fill(null)
  .map((_, index) => ({
    key: index + 1,
    text: `${index + 1} time slot${index !== 0 ? "s" : ""}`,
  }));

const mapDispatchToProps = {
  updatePackage: updatePackageAction,
  uploadPackageImage: uploadPackageImageAction,
  removeSchedule: removeSchedulePackageAction,
  selectPackage: selectPackageAction,
  openTenantUrlWithPackage: openTenantUrlWithPackageAction,
  getParentPackageSchedule: getParentPackageScheduleAction,
  getGoTabProductsAndSpotsForVenue: getGoTabProductsAndSpotsForVenueAction,
  getSquareItems: getSquareCatalogsAction,
  getPlans: getPlansAction,
  getQuestions: getQuestionsAction
};
const mapStateToProps = (state: State) => ({
  venue: selectVenue(state),
  packages: selectPackages(state),
  currentPackage: selectPackage(state),
  isImageLoadInProgress: selectIsImageLoadInProgress(state),
  timeSlotDuration: selectTimeSlotDuration(state),
  isLoadingPackage: selectIsLoadingPackage(state),
  currentPackageSchedule: selectCurrentPackageSchedule(state),
  parentSchedule: selectParentPackageSchedule(state),
  revenueCategories: selectCategories(state),
  goTabProducts: selectGoTabProducts(state),
  goTabSpots: selectGoTabSpots(state),
  uiConfig: selectUIConfig(state),
  settings: selectSettingsState(state),
  square: selectSquare(state),
  plans: selectPlans(state),
  questions: selectQuestions(state)
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector> & {
  readonly: boolean;
  timeSlotShifting?: number[];
};

const renderListItem = (item: PackageItem, index: number) => {
  return (
    <div key={index} className="package-item">
      <div className="package-item-name subtitle2">{item.name}</div>
      <div className="package-item-value body2">{item.value}</div>
    </div>
  );
};

const renderInfoListItem = (item: PackageInfoItem, index: number) => {
  if (item.isHTML) {
    return (
      <div key={index} className="package-item">
        <div className="package-item-name subtitle2">{item.name}</div>
        <HtmlRenderer html={item.value} className="package-item-value body2" />
      </div>
    );
  }

  return (
    <div key={index} className="package-item">
      <div className="package-item-name subtitle2">{item.name}</div>
      <div className="package-item-value body2">{item.value}</div>
    </div>
  );
};

const PackageDetails = ({
  updatePackage,
  uploadPackageImage,
  selectPackage,
  removeSchedule,
  openTenantUrlWithPackage,
  getParentPackageSchedule,
  getGoTabProductsAndSpotsForVenue,
  getSquareItems,
  venue,
  packages,
  currentPackage,
  isImageLoadInProgress,
  readonly,
  timeSlotDuration,
  timeSlotShifting,
  isLoadingPackage,
  currentPackageSchedule,
  parentSchedule,
  revenueCategories,
  goTabProducts,
  goTabSpots,
  uiConfig,
  settings,
  square,
  plans,
  getPlans
}: Props) => {
  const resFieldOptions = [...reservationFieldList, ...currentPackage?.questions?.map(question => ({ key: question.id, text: question.displayName })) || []];
  const additionalFields = convertQuestionsToCustomFields(currentPackage?.questions || []);
  const DATE_FORMAT = uiConfig?.dateFormat || "MM/DD/YYYY";
  const newSchedule = {
    title: "",
    priority: 1,
    start: dayjs().format(DATE_FORMAT),
    end: dayjs().add(10, "d").format(DATE_FORMAT),
    inventoryMethod: InventoryMethodTypes.byLanes,
    overrideParentSchedule: false,
    timeSlotsPackage: [],
    startTimeOnly: Array(7).fill(false).join(","),
    enableDiscountedPrice: false,
  };
  const initialColumns: IColumn[] = [
    {
      key: "name",
      name: "NAME",
      fieldName: "title",
      minWidth: 50,
      maxWidth: 250,
      isResizable: true,
      className: "bold-column subtitle2",
      data: "string",
      isPadded: true,
    },
    {
      key: "startDate",
      name: "START DATE",
      fieldName: "start",
      minWidth: 110,
      maxWidth: 150,
      isResizable: true,
      className: "column body2",
      data: "string",
      isPadded: true,
      onRender: (item: CellData) => {
        return <span>{formatScheduleDate(item.start)}</span>;
      },
    },
    {
      key: "endDate",
      name: "END DATE",
      fieldName: "end",
      minWidth: 110,
      maxWidth: 150,
      isResizable: true,
      className: "column body2",
      data: "string",
      isPadded: true,
      onRender: (item: CellData) => {
        return <span>{formatScheduleDate(item.end)}</span>;
      },
    },
    {
      key: "priority",
      name: "PRIORITY",
      fieldName: "priority",
      minWidth: 45,
      maxWidth: 100,
      isResizable: true,
      className: "column body2",
      data: "string",
      isPadded: true,
      isSorted: true,
      isSortedDescending: false,
    },
    {
      key: "action",
      name: "",
      minWidth: 25,
      maxWidth: 25,
      className: "column body2",
      onRender: (item: CellData) => {
        const menuProps = {
          shouldFocusOnMount: true,
          isBeakVisible: false,
          items: [
            {
              key: "newItem",
              text: "Edit",
              onClick: () => editSchedule(item as SchedulePackageSettingDto),
            },
            {
              key: "newItem",
              text: "Clone",
              onClick: () => cloneSchedule(item as SchedulePackageSettingDto),
            },
            {
              key: "newItem",
              text: "Delete",
              onClick: () => removePackageSchedule(item.id),
            },
          ],
        };
        return (
          <ActionButton className="contextual-menu" menuProps={menuProps}>
            <VertIcon />
          </ActionButton>
        );
      },
      isPadded: true,
    },
  ];

  const expendCustomFieldsMapping = (fields: string): CustomFieldsMapping[] => {
    return fields.split(";").map((item, index) => ({
      id: index,
      tsId: item.split(":")[0] || "",
      name: item.split(":")[1] || "",
      param: item.split(":")[2] || "",
    }));
  };
  const foldCustomFieldsMapping = (fields: CustomFieldsMapping[]) => {
    return fields
      .map((item) => `${item.tsId}:${item.name}:${item.param}`)
      .join(";");
  };
  const onUpdateCustomFieldsMapping = (field: CustomFieldsMapping) => {
    const newValue = newCustomFieldsMapping.map((item) =>
      item.id === field.id ? field : item
    );
    setNewCustomFieldsMapping(newValue);
  };
  const onAddCustomFieldsMapping = () => {
    setNewCustomFieldsMapping([
      ...newCustomFieldsMapping,
      { id: newCustomFieldsMapping.length, tsId: "", name: "", param: "" },
    ]);
  };
  const onRemoveCustomFieldsMapping = (field: CustomFieldsMapping) => {
    const newValue = newCustomFieldsMapping
      .filter((item) => item.id !== field.id)
      .map((item, index) => ({ ...item, id: index }));
    setNewCustomFieldsMapping(newValue);
  };

  const expendRevenueCategoriesMapping = (
    fields: string
  ): RevenueCategoriesMapping[] => {
    return fields.split(";").map((item, index) => ({
      id: index,
      category: item.split(":")[0] || "",
      value: item.split(":")[1] || "",
    }));
  };
  const foldRevenueCategoriesMapping = (fields: RevenueCategoriesMapping[]) => {
    return fields
      .map((item) => `${item.category}:${item.value || 0}`)
      .join(";");
  };
  const onUpdateRevenueCategoriesMapping = (
    field: RevenueCategoriesMapping
  ) => {
    setErrorRevenueCategoriesMapping("");
    const newValue = newRevenueCategoriesMapping.map((item) =>
      item.id === field.id ? field : item
    );
    setNewRevenueCategoriesMapping(newValue);
  };
  const onAddRevenueCategoriesMapping = () => {
    setErrorRevenueCategoriesMapping("");
    setNewRevenueCategoriesMapping([
      ...newRevenueCategoriesMapping,
      { id: newRevenueCategoriesMapping.length, category: "", value: "" },
    ]);
  };
  const onRemoveRevenueCategoriesMapping = (
    field: RevenueCategoriesMapping
  ) => {
    setErrorRevenueCategoriesMapping("");
    const newValue = newRevenueCategoriesMapping
      .filter((item) => item.id !== field.id)
      .map((item, index) => ({ ...item, id: index }));
    setNewRevenueCategoriesMapping(newValue);
  };

  const expendPackageTagsMapping = (tags: string): PackageTagsMapping[] => {
    return tags.split(";").map((item, index) => {
      const parsed = item.split(":");
      return {
        id: index,
        tag: parsed[0] || "",
        priority: parseInt(parsed[1] || "1"),
        available: parsed[2] === "true",
      };
    });
  };
  const foldPackageTagsMapping = (tags: PackageTagsMapping[]) => {
    return tags
      .map((item) => `${item.tag}:${item.priority}:${item.available}`)
      .join(";");
  };
  const onUpdatePackageTagsMapping = (tag: PackageTagsMapping) => {
    const newValue = newPackageTagsMapping.map((item) =>
      item.id === tag.id ? tag : item
    );
    setNewPackageTagsMapping(newValue);
  };
  const onAddPackageTagsMapping = () => {
    setNewPackageTagsMapping([
      ...newPackageTagsMapping,
      {
        id: newPackageTagsMapping.length,
        tag: "",
        priority: 1,
        available: false,
      },
    ]);
  };
  const onRemovePackageTagsMapping = (tag: PackageTagsMapping) => {
    const newValue = newPackageTagsMapping
      .filter((item) => item.id !== tag.id)
      .map((item, index) => ({ ...item, id: index }));
    setNewPackageTagsMapping(newValue);
  };

  const expendAssignDuration = (o: string): AssignDuration[] => {
    return o.split(";").map((item, index) => ({
      id: index,
      guests: item.split(":")[0] || "",
      duration: item.split(":")[1] || "",
    }));
  };
  const foldAssignDuration = (o: AssignDuration[]) => {
    return o.map((item) => `${item.guests}:${item.duration}`).join(";");
  };
  const onUpdateAssignDuration = (o: AssignDuration) => {
    const newValue = newAssignDuration.map((item) =>
      item.id === o.id ? o : item
    );
    setNewAssignDuration(newValue);
  };
  const onAddAssignDuration = () => {
    setNewAssignDuration([
      ...newAssignDuration,
      { id: newAssignDuration.length, guests: "", duration: "" },
    ]);
  };
  const onRemoveAssignDuration = (o: AssignDuration) => {
    const newValue = newAssignDuration
      .filter((item) => item.id !== o.id)
      .map((item, index) => ({ ...item, id: index }));
    setNewAssignDuration(newValue);
  };
  const onUpdateAssignDurationForAdmin = (o: AssignDuration) => {
    const newValue = newAssignDurationForAdmin.map((item) =>
      item.id === o.id ? o : item
    );
    setNewAssignDurationForAdmin(newValue);
  };
  const onAddAssignDurationForAdmin = () => {
    setNewAssignDurationForAdmin([
      ...newAssignDurationForAdmin,
      { id: newAssignDurationForAdmin.length, guests: "", duration: "" },
    ]);
  };
  const onRemoveAssignDurationForAdmin = (o: AssignDuration) => {
    const newValue = newAssignDurationForAdmin
      .filter((item) => item.id !== o.id)
      .map((item, index) => ({ ...item, id: index }));
    setNewAssignDurationForAdmin(newValue);
  };

  const isSquareConnected =
    settings.squareIsEnable && venue?.squareIsEnable && venue.squareLocationId;

  useEffect(() => {
    if (isSquareConnected && venue?.squareLocationId) {
      getSquareItems(venue.squareLocationId);
    }
  }, []);

  useEffect(() => {
    setActiveCustomFields(currentPackage?.activeCustomFields || "");
    setMandatoryCustomFields(currentPackage?.mandatoryCustomFields || "");
    setAdminOnlyCustomFields(currentPackage?.adminOnlyCustomFields || "");
    setNewCustomFieldsMapping(
      expendCustomFieldsMapping(
        currentPackage?.tripleSeatCustomFieldsMapping || ""
      )
    );
    setNewRevenueCategoriesMapping(
      expendRevenueCategoriesMapping(
        currentPackage?.revenueCategoriesMapping || ""
      )
    );
    setNewPackageTagsMapping(
      expendPackageTagsMapping(currentPackage?.tags || "")
    );
    setColumns(initialColumns);
    setSchedules(
      orderBy(currentPackage?.schedulesPackage, ["priority"], ["desc"]) || []
    );
    setEnableAssignDuration(currentPackage?.enableAssignDuration || false);
    setNewAssignDuration(
      expendAssignDuration(currentPackage?.assignDuration || "")
    );
    setNewAssignDurationForAdmin(
      expendAssignDuration(currentPackage?.assignDurationForAdmin || "")
    );
    setServiceFees(
      currentPackage?.serviceCharge
        ? currentPackage.serviceCharge.split(",")
        : []
    );
    setServiceFeeNames(
      currentPackage?.serviceChargeName
        ? currentPackage.serviceChargeName.split(",")
        : new Array(currentPackage?.serviceCharge.split(",").length).fill("")
    );
    setServiceFeeTypes(
      currentPackage?.serviceChargeType
        ? currentPackage.serviceChargeType.split(",")
        : new Array(currentPackage?.serviceCharge.split(",").length).fill("")
    );
    setTaxForServiceFees(
      currentPackage?.taxForServiceCharge
        ? currentPackage.taxForServiceCharge.split(",")
        : new Array(currentPackage?.serviceCharge.split(",").length).fill("")
    );
    setReservationTaxes(currentPackage?.reservationTaxes || "");
    setIsOpenReservationTaxes(false);
    setCardColor(currentPackage?.cardColor || DEFAULT_CARD_COLOR);
    if (currentPackage) {
      getParentPackageSchedule(currentPackage.id);
    }
    setGoTabProductId(currentPackage?.goTabProductId);
    setGoTabSpotUuid(currentPackage?.goTabSpotUuid);

    if (isSquareConnected) {
      setSquareItemIds(currentPackage?.squareItemIds || []);
    }

    if (!venue?.enableGoTabIntegration || !venue?.goTabLocation) {
      return;
    } else if (
      (goTabProducts.length === 0 && venue?.goTabProductId) ||
      (goTabSpots.length === 0 && venue?.goTabSpotUuid)
    ) {
      getGoTabProductsAndSpotsForVenue(venue.goTabLocation);
    }


  }, [currentPackage]);

  useEffect(() => {
    setReservationDetails(currentPackage?.reservationDetails || "");
    setSelectTime(currentPackage?.selectTime || "");
  }, [currentPackage?.reservationDetails, currentPackage?.selectTime])

  useEffect(() => {
    setPackagePolicyOverride(currentPackage?.packagePolicyOverride || false);
    setPackagePolicyText(currentPackage?.packagePolicyText || '');
    setPackagePolicyTitle(currentPackage?.packagePolicyTitle || '');
    setShowPolicy(currentPackage?.showPolicy || false);
  }, [currentPackage?.packagePolicyOverride, currentPackage?.packagePolicyText, currentPackage?.packagePolicyTitle, currentPackage?.showPolicy])

  useEffect(() => {
    setParentScheduleParams(
      orderBy(parentSchedule, ["priority"], ["desc"])[0] || []
    );
  }, [parentSchedule]);
  useEffect(() => {
    if (goTabProducts.length > 0) {
      const productList = goTabProducts.map((item) => ({
        key: item.productUuid,
        text: item.name,
      }));
      setGoTabProductList(productList);
    }
    if (goTabSpots.length > 0) {
      const spotList = goTabSpots.map((item) => ({
        key: item.spotUuid,
        text: item.name,
      }));
      setGoTabSpotList(spotList);
    }
  }, [goTabProducts, goTabSpots]);

  useEffect(() => { getPlans() }, [getPlans])

  const { isMiddleScreen } = useViewport();
  const [isOpenScheduleEdit, setIsOpenScheduleEdit] = useState(false);
  const [schedulePackage, setSchedulePackage] = useState<
    SchedulePackageSettingDto | CreateSchedulePackageSettingDto
  >({ ...newSchedule, packageId: currentPackage?.id || "" });
  const [scheduleId, setScheduleId] = useState("");
  const [isRemoveOpen, setIsRemoveOpen] = useState(false);
  const [isOpenCurrentPackageSchedule, setIsOpenCurrentPackageSchedule] =
    useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenMembershipsSettings, setIsOpenMembershipSettings] = useState(false);
  const [isClonePackage, setIsClonePackage] = useState(false);
  const [isOpenEditTripleSeatSettings, setIsOpenEditTripleSeatSettings] =
    useState(false);
  const [isOpenEditSeatNinjaSettings, setIsOpenEditSeatNinjaSettings] =
    useState(false);
  const [isOpenEditPackageCancellation, setIsOpenEditPackageCancellation] =
    useState(false);
  const [
    isOpenEditAdminPackageReservation,
    setIsOpenEditAdminPackageReservation,
  ] = useState(false);
  const [activeCustomFields, setActiveCustomFields] = useState<string>(
    currentPackage?.activeCustomFields || ""
  );
  const [mandatoryCustomFields, setMandatoryCustomFields] = useState<string>(
    currentPackage?.mandatoryCustomFields || ""
  );
  const [adminOnlyCustomFields, setAdminOnlyCustomFields] = useState<string>(
    currentPackage?.adminOnlyCustomFields || ""
  );
  const [newCustomFieldsMapping, setNewCustomFieldsMapping] = useState<
    CustomFieldsMapping[]
  >(
    expendCustomFieldsMapping(
      currentPackage?.tripleSeatCustomFieldsMapping || ""
    )
  );
  const [newRevenueCategoriesMapping, setNewRevenueCategoriesMapping] =
    useState<RevenueCategoriesMapping[]>(
      expendRevenueCategoriesMapping(
        currentPackage?.revenueCategoriesMapping || ""
      )
    );
  const [errorRevenueCategoriesMapping, setErrorRevenueCategoriesMapping] =
    useState<string>("");
  const [columns, setColumns] = useState<IColumn[]>(initialColumns);
  const [schedules, setSchedules] = useState<
    SchedulePackageSettingDto[] | undefined
  >(orderBy(currentPackage?.schedulesPackage, ["priority"], ["desc"]) || []);
  const [newPackageTagsMapping, setNewPackageTagsMapping] = useState<
    PackageTagsMapping[]
  >(expendPackageTagsMapping(currentPackage?.tags || ""));
  const [enableAssignDuration, setEnableAssignDuration] = useState(
    currentPackage?.enableAssignDuration || false
  );
  const [packagePolicyOverride, setPackagePolicyOverride] = useState(
    currentPackage?.packagePolicyOverride || false
  );
  const [packagePolicyText, setPackagePolicyText] = useState(
    currentPackage?.packagePolicyText || ''
  );
  const [packagePolicyTitle, setPackagePolicyTitle] = useState(
    currentPackage?.packagePolicyTitle || ''
  );

  const [showPolicy, setShowPolicy] = useState(
    currentPackage?.showPolicy || false
  );

  const [newAssignDuration, setNewAssignDuration] = useState<AssignDuration[]>(
    expendAssignDuration(currentPackage?.assignDuration || "")
  );
  const [newAssignDurationForAdmin, setNewAssignDurationForAdmin] = useState<
    AssignDuration[]
  >(expendAssignDuration(currentPackage?.assignDurationForAdmin || ""));
  const [serviceFees, setServiceFees] = useState(
    currentPackage?.serviceCharge ? currentPackage.serviceCharge.split(",") : []
  );
  const [serviceFeeNames, setServiceFeeNames] = useState(
    currentPackage?.serviceChargeName
      ? currentPackage.serviceChargeName.split(",")
      : new Array(currentPackage?.serviceCharge.split(",").length).fill("")
  );
  const [serviceFeeTypes, setServiceFeeTypes] = useState(
    currentPackage?.serviceChargeType
      ? currentPackage.serviceChargeType.split(",")
      : new Array(currentPackage?.serviceCharge.split(",").length).fill("")
  );
  const [taxForServiceFees, setTaxForServiceFees] = useState(
    currentPackage?.taxForServiceCharge
      ? currentPackage.taxForServiceCharge.split(",")
      : new Array(currentPackage?.serviceCharge.split(",").length).fill("")
  );
  const [parentScheduleParams, setParentScheduleParams] =
    useState<ParentScheduleDto>(
      orderBy(parentSchedule, ["priority"], ["desc"])[0] || []
    );
  const [reservationTaxes, setReservationTaxes] = useState<string>(
    currentPackage?.reservationTaxes || ""
  );
  const [isOpenReservationTaxes, setIsOpenReservationTaxes] =
    useState<boolean>(false);
  const [cardColor, setCardColor] = useState<string>(
    currentPackage?.cardColor || DEFAULT_CARD_COLOR
  );
  const [goTabProductId, setGoTabProductId] = useState(
    currentPackage?.goTabProductId
  );
  const [goTabProductList, setGoTabProductList] = useState<IDropdownOption[]>(
    []
  );
  const [goTabSpotUuid, setGoTabSpotUuid] = useState(
    currentPackage?.goTabSpotUuid
  );
  const [goTabSpotList, setGoTabSpotList] = useState<IDropdownOption[]>([]);

  const [squareItemIds, setSquareItemIds] = useState<string[]>(
    currentPackage?.squareItemIds || []
  );
  const [reservationDetails, setReservationDetails] = useState(currentPackage?.reservationDetails || "");
  const [selectTime, setSelectTime] = useState(currentPackage?.selectTime || "");
  const splitCategories = revenueCategories
    .split(";")
    .filter((string) => !!string);
  const revenueCategoriesOptions = splitCategories.map((category) => {
    const key = category.split(":")[0];
    return {
      key,
      text: key,
    };
  });

  let taxOptions =
    !!venue?.taxes && venue?.taxes.length > 0
      ? venue.taxes.map((tax) => {
        const taxAmount =
          tax.type === FeeType.FLAT_RATE ? `($${tax.tax})` : `(${tax.tax}%)`;
        const taxLabel = `${tax.name} ${taxAmount}`;
        return {
          key: tax.name,
          text: taxLabel,
        };
      })
      : [];

  const feeTaxOptions: IDropdownOption[] = [
    {
      key: ReservationTaxType.noTax,
      text: ReservationTaxType.noTax,
    },
    {
      key: ReservationTaxType.venueTax,
      text: `${ReservationTaxType.venueTax} (${venue?.tax || 0}%)`,
    },
  ];

  let customFeeTaxOptions = feeTaxOptions.concat(taxOptions);

  const editPackage = () => {
    if (currentPackage) {
      setIsClonePackage(false);
      setIsOpenEdit(true);
      selectPackage(currentPackage);
    }
  };
  const editMembershipSettings = () => {
    setIsOpenMembershipSettings(true);
  };

  const editTripleSeatSettings = () => {
    setIsOpenEditTripleSeatSettings(true);
  };

  const editSeatNinjaSettings = () => {
    setIsOpenEditSeatNinjaSettings(true);
  };

  const editPackageCancellation = () => {
    setIsOpenEditPackageCancellation(true);
  };

  const editAdminPackageReservation = () => {
    setIsOpenEditAdminPackageReservation(true);
  };

  const onChangeActivePackage = () => {
    if (currentPackage) {
      updatePackage({ ...currentPackage, status: !currentPackage.status });
    }
  };
  const onChangeAdminOnlyPackage = () => {
    if (currentPackage) {
      updatePackage({
        ...currentPackage,
        adminOnly: !currentPackage.adminOnly,
      });
    }
  };
  const onChangeIsHiddenPackage = () => {
    if (currentPackage) {
      updatePackage({ ...currentPackage, isHidden: !currentPackage.isHidden });
    }
  };

  const onChangeContactOnlyPackage = () => {
    if (currentPackage) {
      updatePackage({
        ...currentPackage,
        contactOnly: !currentPackage.contactOnly,
      });
    }
  };

  const onChangeHidePackageDuration = () => {
    if (currentPackage) {
      updatePackage({
        ...currentPackage,
        hidePackageDuration: !currentPackage.hidePackageDuration,
      });
    }
  };

  const onChangeHideInquiryRedirect = () => {
    if (currentPackage) {
      updatePackage({
        ...currentPackage,
        hideInquiryRedirect: !currentPackage.hideInquiryRedirect,
      });
    }
  }

  const onChangeHidePrice = () => {
    if (currentPackage) {
      updatePackage({
        ...currentPackage,
        hidePrice: !currentPackage.hidePrice,
      });
    }
  }

  const onChangeHidePriceOnTimeCard = () => {
    if (currentPackage) {
      updatePackage({
        ...currentPackage,
        hidePriceOnTimeCard: !currentPackage.hidePriceOnTimeCard,
      });
    }
  }

  const onChangeSyncWithSteltronicPackage = () => {
    if (currentPackage) {
      updatePackage({
        ...currentPackage,
        syncWithSteltronic: !currentPackage.syncWithSteltronic,
      });
    }
  };

  const onChangeEnableAboutGolfIntegration = () => {
    if (currentPackage) {
      updatePackage({
        ...currentPackage,
        enableAboutGolfIntegration: !currentPackage.enableAboutGolfIntegration,
      });
    }
  };

  const onChangeEnableGotTabIntegration = () => {
    if (currentPackage) {
      updatePackage({
        ...currentPackage,
        enableGoTabIntegration: !currentPackage.enableGoTabIntegration,
      });
    }
  }

  const onChangeParentSchedule = (key?: string) => {
    if (currentPackage) {
      const parentSchedule = key || null;
      updatePackage({ ...currentPackage, parentSchedule });
    }
  };
  const uploadPackageImageFile = (files: FileList | null) => {
    if (files && currentPackage?.id) {
      uploadPackageImage(files[0], currentPackage?.id);
    }
  };
  const onUpdatePackageCustomFieldsMapping = () => {
    if (newCustomFieldsMapping.length > 0) {
      updatePackage({
        ...currentPackage,
        tripleSeatCustomFieldsMapping: foldCustomFieldsMapping(
          newCustomFieldsMapping
        ),
      });
    } else {
      updatePackage({ ...currentPackage, tripleSeatCustomFieldsMapping: null });
    }
  };
  const onUpdatePackageRevenueCategoriesMapping = () => {
    if (newRevenueCategoriesMapping.length > 0) {
      let isError = false;
      //check duplicate categories
      revenueCategoriesOptions.forEach((category) => {
        const revenueCategories = newRevenueCategoriesMapping.filter(
          (revenue) => revenue.category === category.key
        );
        if (revenueCategories.length > 1) {
          setErrorRevenueCategoriesMapping("You have duplicate categories");
          isError = true;
        }
      });
      //check empty values
      let totalPercentage = 0;
      newRevenueCategoriesMapping.forEach((revenue) => {
        const localPercentage =
          !!revenue.value && !isNaN(+revenue.value) ? +revenue.value : 0;
        totalPercentage = totalPercentage + localPercentage;
        if (!revenue.category || !revenue.value) {
          setErrorRevenueCategoriesMapping("All fields are required");
          isError = true;
        }
      });
      //check total percentage
      if (totalPercentage !== 100) {
        setErrorRevenueCategoriesMapping(
          `Total percentage should be equal 100. Current percent is ${totalPercentage}`
        );
        isError = true;
      }
      if (!isError) {
        updatePackage({
          ...currentPackage,
          revenueCategoriesMapping: foldRevenueCategoriesMapping(
            newRevenueCategoriesMapping
          ),
        });
      }
    } else {
      updatePackage({ ...currentPackage, revenueCategoriesMapping: null });
    }
  };
  const onUpdatePackagePackageTagsMapping = () => {
    if (newPackageTagsMapping.length > 0) {
      updatePackage({
        ...currentPackage,
        tags: foldPackageTagsMapping(newPackageTagsMapping),
      });
    } else {
      updatePackage({ ...currentPackage, tags: "" });
    }
  };
  const addSchedule = () => {
    setSchedulePackage({ ...newSchedule, packageId: currentPackage?.id || "", ageGroups: currentPackage?.ageGroups });
    setIsOpenScheduleEdit(true);
  };
  const editSchedule = (schedule: SchedulePackageSettingDto) => {
    setSchedulePackage({ ...schedule, ageGroups: currentPackage?.ageGroups });
    setIsOpenScheduleEdit(true);
  };
  const cloneSchedule = (schedule: SchedulePackageSettingDto) => {
    const { id, ...withoutId } = schedule;
    const newSchedule = {
      ...withoutId,
      title: "",
      changed: true,
      isNew: true,
      priority: schedule.priority + 1,
      timeSlotsPackage: schedule.timeSlotsPackage.map((item) => ({
        ...item,
        id: nanoid(),
        packageId: schedule.packageId,
        schedulePackageId: "new",
        changed: true,
        isNew: true,
      })),
    };
    setSchedulePackage(newSchedule);
    setIsOpenScheduleEdit(true);
  };
  const removePackageSchedule = (id: string) => {
    setScheduleId(id);
    setIsRemoveOpen(true);
  };
  const confirmedRemovePackageSchedule = (isConfirm: boolean) => {
    if (isConfirm) {
      removeSchedule(scheduleId);
    }
    closeRemoveDialog();
  };
  const closeRemoveDialog = () => {
    setScheduleId("");
    setIsRemoveOpen(false);
  };

  const getPatch = () => {
    const newServiceFee =
      serviceFees.length > 0
        ? serviceFees.map((item) => (+item).toFixed(2)).join(",")
        : "";
    const newServiceFeeName =
      serviceFeeNames.length > 0 ? serviceFeeNames.join(",") : "";
    const newServiceFeeType =
      serviceFeeTypes.length > 0 ? serviceFeeTypes.join(",") : "";
    let newTaxForServiceFee = "";
    if (taxForServiceFees.length > 0) {
      const taxes = taxForServiceFees.map(
        (tax) => tax || ReservationTaxType.noTax
      );
      newTaxForServiceFee = taxes.join(",");
    }
    return {
      taxForServiceCharge: newTaxForServiceFee,
      serviceCharge: newServiceFee,
      serviceChargeName: newServiceFeeName,
      serviceChargeType: newServiceFeeType,
    };
  };

  const onUpdateServiceFees = (value: string, index: number) => {
    setServiceFees(
      serviceFees.map((item, i) => {
        if (i === index) {
          return value;
        }
        return item;
      })
    );
  };
  const onUpdateServiceFeeNames = (value: string, index: number) => {
    setServiceFeeNames(
      serviceFeeNames.map((item, i) => {
        if (i === index) {
          return value;
        }
        return item;
      })
    );
  };
  const onUpdateServiceFeeTypes = (value: string, index: number) => {
    setServiceFeeTypes(
      serviceFeeTypes.map((item, i) => {
        if (i === index) {
          return value;
        }
        return item;
      })
    );
  };
  const onUpdateTaxForServiceFees = (value: string, index: number) => {
    setTaxForServiceFees(
      taxForServiceFees.map((item, i) => {
        if (i === index) {
          return value;
        }
        return item;
      })
    );
  };
  const onRemoveServiceFee = (index: number) => {
    setServiceFees(serviceFees.filter((_, i) => i !== index));
    setServiceFeeNames(serviceFeeNames.filter((_, i) => i !== index));
    setServiceFeeTypes(serviceFeeTypes.filter((_, i) => i !== index));
    setTaxForServiceFees(taxForServiceFees.filter((_, i) => i !== index));
  };
  const onAddServiceFee = () => {
    setServiceFees([...serviceFees, ""]);
    setServiceFeeNames([...serviceFeeNames, ""]);
    setServiceFeeTypes([...serviceFeeTypes, ""]);
    setTaxForServiceFees([...taxForServiceFees, ""]);
  };
  const onUpdateServiceFee = () => {
    const patch = getPatch();
    updatePackage({ ...currentPackage, ...patch });
  };

  const onUpdateCardColor = () => {
    updatePackage({ ...currentPackage, cardColor });
  };

  const renderRow = (props: any) => (
    <DetailsRow {...props} onClick={() => onItemInvoked(props?.item)} />
  );
  const onItemInvoked = (item: any): void => {
    editSchedule(item as SchedulePackageSettingDto);
  };

  const venueScheduleOption = {
    key: "",
    text: "Venue Schedule",
  };
  const parentScheduleList = [
    venueScheduleOption,
    ...packages.map((selectedPackage) => ({
      key: selectedPackage.id,
      text: selectedPackage.name,
    })),
  ];
  const weeksForBooking = getBookingDuration(currentPackage?.daysForBooking);
  const weeksForBookingForAdmin = getBookingDuration(
    currentPackage?.daysForBookingForAdmin
  );
  const customFieldsList = Object.keys(customFields).map(
    (customField: string, index) => {
      let isSelected = activeCustomFieldsToUIModel(activeCustomFields).includes(customField);
      let isMandatory = activeCustomFieldsToUIModel(mandatoryCustomFields).includes(customField);
      let isAdminOnly = activeCustomFieldsToUIModel(adminOnlyCustomFields).includes(customField);
      const checkboxLabel = customFields[customField]?.fieldsName || additionalFields[customField]?.fieldsName;
      return (
        <div key={customField}>
          {index === 0 &&
            <div className="custom-field-toggles">
              <div className="mandatory">Mandatory</div>
              <div className="mandatory">Admin Only</div>
            </div>
          }
          <div className="custom-field-container">
            <Checkbox
              className="custom-field"
              label={checkboxLabel}
              checked={isSelected}
              onChange={(_: any, checked?: boolean) =>
                setActiveCustomFields(
                  changeActiveCustomFields(
                    customField,
                    activeCustomFields,
                    checked,
                    additionalFields
                  )
                )
              }
              disabled={readonly}
            />
            <div className="custom-field-toggles custom-field-enabled">
              <Toggle
                className="row toggle"
                checked={isMandatory}
                onChange={(_: any, checked?: boolean) =>
                  setMandatoryCustomFields(
                    changeActiveCustomFields(
                      customField,
                      mandatoryCustomFields,
                      checked,
                      additionalFields
                    )
                  )
                }
                disabled={readonly || !isSelected}
              />
              <Toggle
                className="row toggle"
                checked={isAdminOnly}
                onChange={(_: any, checked?: boolean) =>
                  setAdminOnlyCustomFields(
                    changeActiveCustomFields(
                      customField,
                      adminOnlyCustomFields,
                      checked,
                      additionalFields
                    )
                  )
                }
                disabled={readonly || !isSelected}
              />

            </div>
          </div>
        </div>
      );
    }
  );
  const additionalFieldsList = additionalFields && Object.keys(additionalFields).length > 0 ? Object.keys(additionalFields).map(
    (customField: string, index) => {
      let isSelected = activeCustomFieldsToUIModel(activeCustomFields).includes(customField)
      let isMandatory = activeCustomFieldsToUIModel(mandatoryCustomFields).includes(customField);
      let isAdminOnly = activeCustomFieldsToUIModel(adminOnlyCustomFields).includes(customField);
      const checkboxLabel = additionalFields[customField]?.fieldsName
      return (
        <div key={customField}>
          {index === 0 &&
            <div className="custom-field-toggles">
              <div className="mandatory">Mandatory</div>
              <div className="mandatory">Admin Only</div>
            </div>
          }
          <div className="custom-field-container">
            <Checkbox
              className="custom-field"
              label={checkboxLabel}
              checked={isSelected}
              onChange={(_: any, checked?: boolean) =>
                setActiveCustomFields(
                  changeActiveCustomFields(
                    customField,
                    activeCustomFields,
                    checked,
                    additionalFields
                  )
                )
              }
              disabled={readonly}
            />
            <div className="custom-field-toggles custom-field-enabled">
              <Toggle
                className="row toggle"
                checked={isMandatory}
                onChange={(_: any, checked?: boolean) =>
                  setMandatoryCustomFields(
                    changeActiveCustomFields(
                      customField,
                      mandatoryCustomFields,
                      checked,
                      additionalFields
                    )
                  )
                }
                disabled={readonly || !isSelected}
              />
              <Toggle
                className="row toggle"
                checked={isAdminOnly}
                onChange={(_: any, checked?: boolean) =>
                  setAdminOnlyCustomFields(
                    changeActiveCustomFields(
                      customField,
                      adminOnlyCustomFields,
                      checked,
                      additionalFields
                    )
                  )
                }
                disabled={readonly || !isSelected}
              />

            </div>
          </div>
        </div>
      );
    }
  ) : null;
  const onSaveCustomFields = () => {
    if (currentPackage) {
      updatePackage({
        ...currentPackage,
        activeCustomFields,
        mandatoryCustomFields,
        adminOnlyCustomFields,
      });
    }
  };


  const onSaveAssignDuration = () => {
    const patch = {
      enableAssignDuration: !!enableAssignDuration,
      assignDuration: foldAssignDuration(newAssignDuration),
      assignDurationForAdmin: foldAssignDuration(newAssignDurationForAdmin),
    };
    updatePackage({ ...currentPackage, ...patch });
  };

  const onSavePackagePolicies = () => {
    const patch = {
      packagePolicyOverride,
      packagePolicyText,
      packagePolicyTitle,
      showPolicy
    }
    if (currentPackage) {
      updatePackage({ ...currentPackage, ...patch });
    }
  }

  const skipColumn = (column: IColumn) => {
    return column.key === "action";
  };

  const onColumnClick = (ev: any, column: IColumn | undefined) => {
    if (!column || skipColumn(column)) return;
    const newColumns: IColumn[] = [...columns];
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        if (currColumn.isSortedDescending === undefined) {
          currColumn.isSortedDescending = false;
        } else {
          currColumn.isSortedDescending = !currColumn.isSortedDescending;
        }
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const sortType = column && column.isSortedDescending ? "desc" : "asc";
    const fieldName = column && column.fieldName ? column.fieldName : "";
    newColumns.length && setColumns(newColumns);
    setSchedules(
      orderBy(currentPackage?.schedulesPackage, [fieldName], [sortType]) || []
    );
  };
  const preBufferTime = `${durationSlotsToTime(
    currentPackage?.preBufferTimeForReservation.toString(),
    venue?.timeSlotDuration
  )[0].toLowerCase()}`;
  const bufferTime = `${durationSlotsToTime(
    currentPackage?.bufferTimeForReservation.toString(),
    venue?.timeSlotDuration
  )[0].toLowerCase()}`;
  const guestOptions: IDropdownOption[] = Array(
    currentPackage?.maxGuests ? +currentPackage?.maxGuests : 20
  )
    .fill(null)
    .map((_, index) => ({
      key: index + 1,
      text: `${index + 1} guest${index !== 0 ? "s" : ""}`,
    }));
  const filterGuestOption = (id: number) => {
    if (id === 0) {
      return guestOptions.filter((option) => option.key === 1);
    }
    return guestOptions.filter((option) => option.key !== 1);
  };

  const onSelectReservationTax = (taxId: string, checked: boolean) => {
    if (currentPackage) {
      let newReservationTaxes = reservationTaxes;
      if (taxId === ReservationTaxType.noTax) {
        if (checked) {
          newReservationTaxes = taxId;
        } else {
          newReservationTaxes = "";
        }
      } else {
        if (checked) {
          newReservationTaxes = `${newReservationTaxes}${newReservationTaxes.length > 0 ? ";" : ""
            }${taxId}`;
        } else {
          const selectedTaxes = newReservationTaxes.split(";");
          const newTaxesArray = selectedTaxes.filter((tax) => tax !== taxId);
          newReservationTaxes =
            newTaxesArray.length > 0 ? newTaxesArray.join(";") : "";
        }
      }
      setReservationTaxes(newReservationTaxes);
    }
  };
  const onUpdateReservationTaxes = () => {
    if (currentPackage && reservationTaxes) {
      updatePackage({ ...currentPackage, reservationTaxes });
      setIsOpenReservationTaxes(false);
    }
  };

  const onGetGoTabProductAndSpotList = () => {
    if (!!venue?.goTabLocation) {
      getGoTabProductsAndSpotsForVenue(venue.goTabLocation);
    }
  };

  const onSaveGoTabFields = () => {
    if (currentPackage && !!venue?.goTabLocation) {
      updatePackage({
        ...currentPackage,
        goTabProductId: goTabProductId || "",
        goTabSpotUuid: goTabSpotUuid || "",
      });
    }
  };

  const onGetSquareItemList = () => {
    if (isSquareConnected && venue?.squareLocationId) {
      getSquareItems(venue.squareLocationId);
    }
  };

  const onSaveSquareFields = () => {
    if (currentPackage && isSquareConnected) {
      updatePackage({
        ...currentPackage,
        squareItemIds,
      });
    }
  };

  const onSavePackageLanguage = () => {
    if (currentPackage) {
      updatePackage({
        ...currentPackage,
        reservationDetails,
        selectTime
      });
    }
  }

  let reservationTaxSelectors =
    !!venue?.taxes && venue?.taxes.length > 0
      ? venue.taxes.map((tax) => {
        let isSelected = reservationTaxes.split(";").includes(tax.name);
        const taxAmount =
          tax.type === FeeType.FLAT_RATE ? `($${tax.tax})` : `(${tax.tax}%)`;
        const taxLabel = `${tax.name} ${taxAmount}`;
        return (
          <Checkbox
            key={tax.id}
            className="tax-selector"
            label={taxLabel}
            checked={isSelected}
            onChange={(_: any, checked?: boolean) =>
              onSelectReservationTax(tax.name, !!checked)
            }
            disabled={
              readonly || reservationTaxes === ReservationTaxType.noTax
            }
          />
        );
      })
      : null;

  const getReservationTaxesList = () => {
    if (
      currentPackage?.reservationTaxes
        .split(";")
        .includes(ReservationTaxType.noTax)
    ) {
      return ReservationTaxType.noTax;
    }
    let reservationTaxList = "";
    if (
      currentPackage?.reservationTaxes
        .split(";")
        .includes(ReservationTaxType.venueTax)
    ) {
      reservationTaxList = `${ReservationTaxType.venueTax} ${venue?.tax ? `(${venue.tax}%)` : "(0%)"
        }`;
    }
    if (!!venue?.taxes && venue?.taxes.length > 0) {
      venue.taxes.forEach((tax) => {
        if (currentPackage?.reservationTaxes.split(";").includes(tax.name)) {
          const taxAmount =
            tax.type === FeeType.FLAT_RATE ? `($${tax.tax})` : `(${tax.tax}%)`;
          reservationTaxList = `${reservationTaxList}${!!reservationTaxList ? "; " : ""
            }${tax.name} ${taxAmount}`;
        }
      });
    }
    return reservationTaxList;
  };

  const onRenderOption = (option: IDropdownOption | undefined): JSX.Element => {
    if (!option) {
      return <div />;
    }
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {option.data && option.data.background && (
          <div
            style={{
              width: "20px",
              height: "20px",
              background: option.data.background,
              marginRight: "20px",
            }}
          />
        )}
        <span>{option.text}</span>
      </div>
    );
  };

  const onRenderTitle = (
    options: IDropdownOption[] | undefined
  ): JSX.Element => {
    if (!options || options.length === 0) {
      return <div />;
    }
    const option = options[0];
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {option.data && option.data.background && (
          <div
            style={{
              width: "20px",
              height: "20px",
              background: option.data.background,
              marginRight: "20px",
            }}
          />
        )}
        <span>{option.text}</span>
      </div>
    );
  };

  const membershipDuration = getBookingDuration(currentPackage?.daysForBookingForMember);

  const membershipPlans = plans.filter(plan => currentPackage?.membershipPlanIds.includes(plan.id)).map(plan => plan.name).join(', ');

  const packageControlsTitle = (
    <>
      <div className="h6">Package Controls</div>
    </>
  );
  const packageInfoTitle = (
    <>
      <div className="h6">Package Info</div>
      <LinkButton onClick={() => editPackage()}>Edit</LinkButton>
    </>
  );
  const packageDetailsTitle = (
    <>
      <div className="h6">Package Details</div>
      <LinkButton onClick={() => editPackage()}>Edit</LinkButton>
    </>
  );
  const serviceFeesTitle = (
    <>
      <div className="h6">Custom Fees</div>
    </>
  );
  const packageCancellationDetailsTitle = (
    <>
      <div className="h6">Cancel and Modify Rules</div>
      <LinkButton onClick={() => editPackageCancellation()}>Edit</LinkButton>
    </>
  );
  const adminPackageReservationTitle = (
    <>
      <div className="h6">Host Application Package Settings</div>
      <LinkButton onClick={() => editAdminPackageReservation()}>
        Edit
      </LinkButton>
    </>
  );
  const packageTagsTitle = (
    <>
      <div className="h6">Package Tags</div>
    </>
  );
  const tripleSeatSettingsTitle = (
    <>
      <div className="h6">Custom Tripleseat Settings</div>
      <LinkButton onClick={() => editTripleSeatSettings()}>Edit</LinkButton>
    </>
  );
  const seatNinjaSettingsTitle = (
    <>
      <div className="h6">Custom SeatNinja Settings</div>
      <LinkButton onClick={() => editSeatNinjaSettings()}>Edit</LinkButton>
    </>
  );
  const customFieldsTitle = (
    <>
      <div className="h6">Preset Checkout Fields</div>
    </>
  );
  const additionalCheckoutFieldsTitle = (
    <>
      <div className="h6">Custom Checkout Fields</div>
    </>
  );
  const customFieldsMappingTitle = (
    <>
      <div className="h6">Custom Fields Mapping</div>
    </>
  );
  const revenueCategoriesMappingTitle = (
    <>
      <div className="h6">Revenue Attribution</div>
    </>
  );

  const assignDurationSettingsTitle = (
    <>
      <div className="h6">Assign Duration</div>
      <div className="title-buttons">
        <Toggle
          className="toggle"
          checked={enableAssignDuration}
          onChange={(_: any, value?: boolean) =>
            setEnableAssignDuration(!!value)
          }
          disabled={readonly}
        />
      </div>
    </>
  );
  const assignDurationForAdminSettingsTitle = (
    <>
      <div className="h6">Assign Duration for admin</div>
    </>
  );
  const imageTitle = (
    <>
      <div className="h6">Images</div>
    </>
  );
  const colorTitle = (
    <>
      <div className="h6">Host App Colors</div>
    </>
  );

  const goTabFieldsTitle = (
    <>
      <div className="h6">GoTab</div>
    </>
  );

  const squareTitle = (
    <>
      <div className="h6">Square</div>
    </>
  );

  const membershipSettingsTitle = (
    <>
      <div className="h6">Membership Settings</div>
      <LinkButton onClick={() => editMembershipSettings()}>Edit</LinkButton>
    </>
  );

  const packageLanguageTitle = (
    <>
      <div className="h6">Package Language</div>
    </>
  );

  const packagePoliciesTitle = (
    <>
      <div className="h6">Package Policies</div>
      <div className="title-buttons">
        <Toggle
          className="toggle"
          checked={packagePolicyOverride}
          onChange={(_: any, value?: boolean) =>
            setPackagePolicyOverride(!!value)
          }
          disabled={readonly}
          inlineLabel
          label={"Override Venue Policies"}
        />
      </div>
    </>
  );

  const packageInfo: PackageInfoItem[] = [
    { name: "Package Name", value: currentPackage?.name || "-" },
    { name: "Package Prefix", value: currentPackage?.prefix || "-" },
    {
      name: "Short Description",
      value: currentPackage?.shortDescription || "-",
    },
    {
      name: "Long Description",
      value: currentPackage?.description || "-",
      isHTML: true,
    },
  ];

  const packageDetails: PackageItem[] = [
    {
      name: "Number of Lanes",
      value: currentPackage?.numberOfLanes.toString() || "-",
    },
    {
      name: "Max number of guests on one lane",
      value: currentPackage?.maxGuestsPerLane?.toString() || "-",
    },
    {
      name: "Fixed Number of Lanes",
      value: currentPackage?.countLanesByGuest ? "No" : "Yes",
    },
    {
      name: "Guest Chooses Duration",
      value: currentPackage?.enableDurationChoice ? "Yes" : "No",
    },
    {
      name: "Duration",
      value: currentPackage?.duration
        ? formatDuration(currentPackage.duration, timeSlotDuration)
        : "-",
    },
    { name: "Duration Options", value: currentPackage?.durationInSlots || "-" },
    { name: "Priority", value: currentPackage?.priority?.toString() || "-" },
    {
      name: "Minimum Guests",
      value: currentPackage?.minGuests?.toString() || "-",
    },
    {
      name: "Maximum Guests",
      value: currentPackage?.maxGuests?.toString() || "-",
    },
    { name: "Pricing Method", value: currentPackage?.method || "-" },
    { name: "Deposit", value: currentPackage?.deposit.toString() || "-" },
    { name: "Deposit Type", value: currentPackage?.depositType || "-" },
    { name: "Lead Time", value: getTime(currentPackage?.leadTime) },
    {
      name: "Link skip ahead (in days)",
      value: `${currentPackage?.shiftingForReservationStart} days`,
    },
    {
      name: "How far in advance can users book?",
      value: weeksForBooking || "-",
    },
    { name: "Buffer time before reservation", value: preBufferTime || "-" },
    { name: "Buffer time after reservation", value: bufferTime || "-" },
    ...(currentPackage?.ageGroups ? [{
      name: 'Age Groups',
      value: parseAgeGroups(currentPackage?.ageGroups || '').map(ageGroup => `${ageGroup.groupName}`).join(', ')
    }] : []),
    { name: 'Email Notifications', value: currentPackage?.eventsEmail || '-' },

  ];

  const packageCancellationDetails: PackageItem[] = [
    {
      name: "Allow Cancellations",
      value: currentPackage?.allowCancellation ? "Yes" : "No",
    },
    {
      name: "Cancellation fee type",
      value: currentPackage?.cancellationFeeType || "-",
    },
    {
      name: "Cancellation fee",
      value: currentPackage?.cancellationFee.toFixed(2) || "-",
    },
    {
      name: "Cancellation Cut Off (hours)",
      value: `${currentPackage?.cancellationCutOff} hours` || "-",
    },
    {
      name: "Allow Modifications",
      value: currentPackage?.allowModification ? "Yes" : "No",
    },
    {
      name: "Modification fee type",
      value: currentPackage?.modificationFeeType || "-",
    },
    {
      name: "Modification fee",
      value: currentPackage?.modificationFee.toFixed(2) || "-",
    },
    {
      name: "Modification Cut Off (hours)",
      value: `${currentPackage?.modificationCutOff} hours` || "-",
    },
  ];

  const adminPackageReservation: PackageItem[] = [
    {
      name: "Minimum Guests for admin",
      value: currentPackage?.minGuestsForAdmin?.toString() || "-",
    },
    {
      name: "Maximum Guests for admin",
      value: currentPackage?.maxGuestsForAdmin?.toString() || "-",
    },
    {
      name: "Max number of guests on one lane for admin",
      value: currentPackage?.maxGuestsPerLaneForAdmin?.toString() || "-",
    },
    {
      name: "Admin cut off time",
      value: currentPackage?.adminCutOffTime?.toString() || "-",
    },
    {
      name: "Duration for admin",
      value: currentPackage?.durationInSlotsForAdmin || "-",
    },
    {
      name: "How far in advance can admins book?",
      value: weeksForBookingForAdmin || "-",
    },
  ];

  const tripleSeatSettings: PackageItem[] = [
    {
      name: "Specific Tripleseats Integration",
      value: currentPackage?.packageSpecificTripleSeatsIntegration
        ? "Yes"
        : "No",
    },
    {
      name: "Location ID",
      value: currentPackage?.tripleSeatLocationId?.toString() || "-",
    },
    {
      name: "Room ID",
      value: currentPackage?.tripleSeatRoomId?.toString() || "-",
    },
    {
      name: "Account ID",
      value: currentPackage?.tripleSeatAccountId?.toString() || "-",
    },
    {
      name: "Contact ID",
      value: currentPackage?.tripleSeatContactId?.toString() || "-",
    },
    {
      name: "Event Type ID",
      value: currentPackage?.tripleSeatEventTypeId?.toString() || "-",
    },
    {
      name: "Status",
      value: formatLabel(currentPackage?.tripleSeatBlockedStatus) || "-",
    },
    {
      name: "Publish Type",
      value: formatLabel(currentPackage?.tripleSeatPublishType) || "-",
    },
    {
      name: "Read Availability",
      value: currentPackage?.tripleSeatReadAvailability
        ? `by ${currentPackage?.tripleSeatAvailabilityType} ${currentPackage?.tripleSeatAvailabilityType ===
          TripleSeatAvailabilityType.CustomField
          ? currentPackage?.tripleSeatAvailabilityFieldId
          : ""
        }`
        : "off",
    },
    {
      name: "Read Status",
      value:
        formatReadStatus(currentPackage?.tripleSeatStatusesOfPulledEvents) ||
        "-",
    },
  ];

  const seatNinjaSettings: PackageItem[] = [
    {
      name: "Specific SeatNinjas Integration",
      value: currentPackage?.packageSpecificSeatNinjasIntegration
        ? "Yes"
        : "No",
    },
    {
      name: "Split Reservations",
      value: currentPackage?.splitSeatNinjaReservations ? "Yes" : "No",
    },
    {
      name: "Restaurant ID",
      value: currentPackage?.seatNinjaRestId?.toString() || "-",
    },
    {
      name: "Party Type",
      value: currentPackage?.seatNinjaPartyType?.toString() || "-",
    },
    {
      name: "Party Flags",
      value: currentPackage?.seatninjaPartyFlags?.toString() || "-",
    },
  ];

  const membershipSettingsInfo: PackageInfoItem[] = [
    {
      name: "Member Only Package",
      value: currentPackage?.isMemberOnlyPackage ? "Yes" : "No",
    },
    { name: "Plans", value: membershipPlans || "-" },
    {
      name: "How far in advance can members book?",
      value: membershipDuration || "-",
    },
    {
      name: "Member lead time",
      value: getTimeExtended(currentPackage?.memberLeadTime),
    },
    {
      name: "Duration for member",
      value: currentPackage?.durationInSlotsForMember || "-",
    },
  ];
  const onViewPackage = () => {
    if (currentPackage && venue) {
      openTenantUrlWithPackage({ p: currentPackage, venue: venue });
    }
  };

  return (
    <div className="package-details-container">
      <div className="package-details">
        <div className="package-details-information-container">
          <div className="package-details-title h4">{currentPackage?.name}</div>
          <div className={`package-info ${isMiddleScreen ? "mobile" : ""}`}>
            <div className="left-panel">
              {isMiddleScreen && (
                <Panel
                  className="panel-shift panel-for-controls"
                  title={packageControlsTitle}
                >
                  <div className="toggles-container">
                    <div className="toggle-active-inactive body2">
                      <Toggle
                        checked={!!currentPackage?.status}
                        onChange={onChangeActivePackage}
                      />
                      <div className="label">
                        {!!currentPackage?.status
                          ? "Package is Active"
                          : "Package is Inactive"}
                      </div>
                    </div>
                    <div className="toggle-active-inactive body2">
                      <Toggle
                        checked={!!currentPackage?.adminOnly}
                        onChange={onChangeAdminOnlyPackage}
                      />
                      <div className="label">Package is Admin only</div>
                    </div>
                    <div className="toggle-active-inactive body2">
                      <Toggle
                        checked={!!currentPackage?.isHidden}
                        onChange={onChangeIsHiddenPackage}
                      />
                      <div className="label">Package is Hidden</div>
                    </div>
                    <div className="toggle-active-inactive body2">
                      <Toggle
                        checked={!!currentPackage?.contactOnly}
                        onChange={onChangeContactOnlyPackage}
                      />
                      <div className="label">Package is Contact only</div>
                    </div>
                    {venue?.enableSteltronic && (
                      <div className="toggle-active-inactive body2">
                        <Toggle
                          checked={!!currentPackage?.syncWithSteltronic}
                          onChange={onChangeSyncWithSteltronicPackage}
                        />
                        <div className="label">Sync With Steltronic</div>
                      </div>
                    )}
                    <div className="toggle-active-inactive body2">
                      <Toggle
                        checked={!!currentPackage?.hidePackageDuration}
                        onChange={onChangeHidePackageDuration}
                      />
                      <div className="label">Hide Package Duration</div>
                    </div>
                    <div className="toggle-active-inactive body2">
                      <Toggle
                        checked={!!currentPackage?.hideInquiryRedirect}
                        onChange={onChangeHideInquiryRedirect}
                      />
                      <div className="label">Hide Inquiry redirect</div>
                    </div>
                    <div className="toggle-active-inactive body2">
                      <Toggle
                        checked={!!currentPackage?.hidePrice}
                        onChange={onChangeHidePrice}
                      />
                      <div className="label">Hide Price</div>
                    </div>
                    <div className="toggle-active-inactive body2">
                      <Toggle
                        checked={!!currentPackage?.hidePriceOnTimeCard}
                        onChange={onChangeHidePriceOnTimeCard}
                      />
                      <div className="label">Hide Price on Time Card</div>
                    </div>
                    {venue?.enableAboutGolfIntegration && (
                      <div className="toggle-active-inactive body2">
                        <Toggle
                          checked={!!currentPackage?.enableAboutGolfIntegration}
                          onChange={onChangeEnableAboutGolfIntegration}
                        />
                        <div className="label">Enable About Golf</div>
                      </div>
                    )}
                    {venue?.enableGoTabIntegration && (
                      <div className="toggle-active-inactive body2">
                        <Toggle
                          checked={!!currentPackage?.enableGoTabIntegration}
                          onChange={onChangeEnableGotTabIntegration}
                        />
                        <div className="label">Sync with GoTab</div>
                      </div>
                    )}
                    {!currentPackage?.contactOnly && (
                      <div className="body2">
                        <FormSelectField
                          label="Parent Schedule"
                          options={parentScheduleList}
                          selectedKey={
                            currentPackage?.parentSchedule ||
                            parentScheduleList[0].key
                          }
                          onChange={(
                            _: React.FormEvent<HTMLDivElement>,
                            item?: IDropdownOption
                          ) => onChangeParentSchedule(item?.key as string)}
                          disabled={readonly}
                        />
                      </div>
                    )}
                  </div>
                  {!currentPackage?.contactOnly && (
                    <>
                      <DefaultButton
                        className="actions-button"
                        onClick={onViewPackage}
                      >
                        <div className="actions-text">
                          <OpenIcon />
                          View Package
                        </div>
                      </DefaultButton>
                      <DefaultButton
                        className="actions-button"
                        onClick={() => setIsOpenCurrentPackageSchedule(true)}
                      >
                        <CalendarIcon />
                        <div className="actions-text">
                          View Package Calendar
                        </div>
                      </DefaultButton>
                    </>
                  )}
                </Panel>
              )}
              {!currentPackage?.contactOnly && (
                <Panel
                  className="panel-shift panel-schedule"
                  title={<div className="h6">Schedule</div>}
                >
                  {schedules && schedules.length > 0 ? (
                    <>
                      <div className="schedule-list">
                        <DetailsList
                          items={schedules}
                          styles={scheduleListStyles}
                          columns={columns}
                          selectionMode={SelectionMode.none}
                          getKey={(item) => item.id}
                          setKey="none"
                          layoutMode={DetailsListLayoutMode.justified}
                          isHeaderVisible={true}
                          className="list"
                          onRenderRow={renderRow}
                          onColumnHeaderClick={onColumnClick}
                        />
                      </div>
                      <div className="add-schedule-button-container">
                        <ColorButton
                          className="empty-schedule-list-button"
                          onClick={addSchedule}
                          disabled={readonly}
                        >
                          <Icon iconName="Add" className="add-icon" />
                          <div className="add-button-text">Add Schedule</div>
                        </ColorButton>
                      </div>
                    </>
                  ) : (
                    <div className="empty-schedule-list-container">
                      <div className="empty-schedule-list">
                        <CalendarIcon className="calendar-icon" />
                        <div className="empty-schedule-list-message">
                          There are no schedules here yet.
                        </div>
                        <ColorButton
                          className="empty-schedule-list-button"
                          onClick={addSchedule}
                          disabled={readonly}
                        >
                          <Icon iconName="Add" className="add-icon" />
                          <div className="add-button-text">Add Schedule</div>
                        </ColorButton>
                      </div>
                    </div>
                  )}
                </Panel>
              )}
              <Panel
                className="panel-shift panel-info"
                title={packageInfoTitle}
              >
                {packageInfo.map((item, index) =>
                  renderInfoListItem(item, index)
                )}
              </Panel>
              <Panel
                className={`panel-shift ${isMiddleScreen ? "mobile" : "panel-with-two-columns"
                  }`}
                title={packageDetailsTitle}
              >
                {packageDetails.map((item, index) =>
                  renderListItem(item, index)
                )}
              </Panel>
              <AssignResource />
              <Panel
                className="panel-shift panel-info"
                title={serviceFeesTitle}
              >
                {serviceFees.length > 0 &&
                  serviceFees.map((_, index) => (
                    <div
                      className={`fields-mapping ${isMiddleScreen ? "mobile" : ""
                        }`}
                      key={index}
                    >
                      <FormTextField
                        label="Fee Name"
                        className="field fee-field"
                        value={serviceFeeNames[index] || ""}
                        onChange={(_: any, value?: string) =>
                          onUpdateServiceFeeNames(value || "", index)
                        }
                        autoComplete="off"
                        disabled={readonly}
                      />
                      <FormSelectField
                        label="Fee Type"
                        className="field fee-field small"
                        options={feeTypeOptions}
                        selectedKey={serviceFeeTypes[index] || ""}
                        onChange={(_: any, option) => {
                          onUpdateServiceFeeTypes(
                            option?.key ? option?.key.toString() : "",
                            index
                          );
                        }}
                        disabled={readonly}
                      />
                      <FormTextField
                        label="Fee"
                        className="field fee-field small"
                        type="number"
                        value={serviceFees[index] || ""}
                        onChange={(_: any, value?: string) =>
                          onUpdateServiceFees(value || "", index)
                        }
                        autoComplete="off"
                        disabled={readonly}
                      />
                      <FormSelectField
                        label="Tax"
                        className="field fee-field small"
                        options={customFeeTaxOptions}
                        selectedKey={
                          taxForServiceFees[index] || ReservationTaxType.noTax
                        }
                        onChange={(_: any, option) => {
                          onUpdateTaxForServiceFees(
                            option?.key
                              ? option?.key.toString()
                              : ReservationTaxType.noTax,
                            index
                          );
                        }}
                        disabled={readonly}
                      />
                      <IconButton
                        className="delete-button"
                        ariaLabel="Remove Service Fee"
                        onClick={() => onRemoveServiceFee(index)}
                      >
                        <TrashIcon />
                      </IconButton>
                    </div>
                  ))}
                <div className={isMiddleScreen ? "mobile" : ""}>
                  <LinkButton
                    className="add-button"
                    onClick={() => onAddServiceFee()}
                  >
                    <Icon iconName="Add" className="add" />
                    Add item
                  </LinkButton>
                </div>
                <div className={isMiddleScreen ? "mobile" : ""}>
                  <ColorButton
                    className="save-button"
                    onClick={onUpdateServiceFee}
                    disabled={readonly}
                  >
                    Save Changes
                  </ColorButton>
                </div>
              </Panel>
              <Panel
                className="panel-shift panel-info"
                title={packageCancellationDetailsTitle}
              >
                {packageCancellationDetails.map((item, index) =>
                  renderListItem(item, index)
                )}
              </Panel>
              <Panel
                className={`panel-shift ${isMiddleScreen ? "mobile" : "panel-with-two-columns"
                  }`}
                title={adminPackageReservationTitle}
              >
                {adminPackageReservation.map((item, index) =>
                  renderListItem(item, index)
                )}
              </Panel>
              <Panel
                className="panel-shift panel-info"
                title={packageTagsTitle}
              >
                {newPackageTagsMapping.map((item) => (
                  <div
                    className={`fields-mapping ${isMiddleScreen ? "mobile" : ""
                      }`}
                    key={item.id}
                  >
                    <FormTextField
                      label="Package Tag"
                      className="field"
                      value={item.tag}
                      onChange={(_: any, value?: string) =>
                        onUpdatePackageTagsMapping({
                          ...item,
                          tag: value || "",
                        })
                      }
                      autoComplete="off"
                      disabled={readonly}
                    />
                    <FormTextField
                      className="field priority-field"
                      label="Priority"
                      type="number"
                      value={item.priority + ""}
                      onChange={(_: any, value?: string) =>
                        onUpdatePackageTagsMapping({
                          ...item,
                          priority: value ? +value : 0,
                        })
                      }
                      autoComplete="off"
                      disabled={readonly}
                    />
                    <Toggle
                      className="available-field"
                      checked={item.available}
                      onChange={() =>
                        onUpdatePackageTagsMapping({
                          ...item,
                          available: !item.available,
                        })
                      }
                    />
                    <IconButton
                      className="delete-button"
                      ariaLabel="Remove Package Tag"
                      onClick={() => onRemovePackageTagsMapping(item)}
                    >
                      <TrashIcon />
                    </IconButton>
                  </div>
                ))}
                <div className={isMiddleScreen ? "mobile" : ""}>
                  <LinkButton
                    className="add-button"
                    onClick={onAddPackageTagsMapping}
                  >
                    <Icon iconName="Add" className="add" />
                    Add tag
                  </LinkButton>
                </div>
                <div className={isMiddleScreen ? "mobile" : ""}>
                  <ColorButton
                    className="save-button"
                    onClick={onUpdatePackagePackageTagsMapping}
                    disabled={readonly}
                  >
                    Save Changes
                  </ColorButton>
                </div>
              </Panel>
              <Panel
                className="panel-shift panel-info"
                title={customFieldsTitle}
              >
                <div className="row">{customFieldsList}</div>
                <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
                  <ColorButton
                    className="save-button"
                    onClick={onSaveCustomFields}
                    disabled={readonly}
                  >
                    Save Changes
                  </ColorButton>
                </div>
              </Panel>
              {additionalFields && Object.keys(additionalFields).length > 0
                ? <Panel
                  className="panel-shift panel-info"
                  title={additionalCheckoutFieldsTitle}
                >
                  <div className="row">{additionalFieldsList}</div>
                  <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
                    <ColorButton
                      className="save-button"
                      onClick={onSaveCustomFields}
                      disabled={readonly}
                    >
                      Save Changes
                    </ColorButton>
                  </div>
                </Panel> : null}
              {uiConfig?.hasMemberships &&
                <Panel className={`panel-shift panel-info ${isMiddleScreen ? "mobile" : "panel-with-two-columns"}`} title={membershipSettingsTitle}>
                  {membershipSettingsInfo.map((item, index) =>
                    renderInfoListItem(item, index)
                  )}
                </Panel>
              }
              <Panel
                className="panel-shift panel-info"
                title={tripleSeatSettingsTitle}
              >
                {tripleSeatSettings.map((item, index) =>
                  renderListItem(item, index)
                )}
              </Panel>
              <Panel
                className="panel-shift panel-info"
                title={seatNinjaSettingsTitle}
              >
                {seatNinjaSettings.map((item, index) =>
                  renderListItem(item, index)
                )}
              </Panel>
              <Panel
                className="panel-shift panel-info"
                title={customFieldsMappingTitle}
              >
                {newCustomFieldsMapping.map((item) => (
                  <div
                    className={`fields-mapping ${isMiddleScreen ? "mobile" : ""
                      }`}
                    key={item.id}
                  >
                    <FormTextField
                      label="TS Custom Fields ID"
                      className="field"
                      value={item.tsId}
                      onChange={(_: any, value?: string) =>
                        onUpdateCustomFieldsMapping({
                          ...item,
                          tsId: value || "",
                        })
                      }
                      autoComplete="off"
                      disabled={readonly}
                    />
                    <FormSelectField
                      label="Reservation Field"
                      className="field"
                      options={resFieldOptions}
                      selectedKey={item.name}
                      onChange={(_: any, option) => {
                        onUpdateCustomFieldsMapping({
                          ...item,
                          name: option?.key ? option?.key.toString() : "",
                          param: "",
                        });
                      }}
                      disabled={readonly}
                    />
                    {item.name === "Custom" ? (
                      <FormTextField
                        label="Custom Text"
                        className="field"
                        value={item.param || ""}
                        onChange={(_: any, value?: string) =>
                          onUpdateCustomFieldsMapping({
                            ...item,
                            param: value || "",
                          })
                        }
                        autoComplete="off"
                        disabled={readonly}
                      />
                    ) : null}
                    <IconButton
                      className="delete-button"
                      ariaLabel="Remove Custom Field"
                      onClick={() => onRemoveCustomFieldsMapping(item)}
                    >
                      <TrashIcon />
                    </IconButton>
                  </div>
                ))}
                <div className={isMiddleScreen ? "mobile" : ""}>
                  <LinkButton
                    className="add-button"
                    onClick={onAddCustomFieldsMapping}
                  >
                    <Icon iconName="Add" className="add" />
                    Add item
                  </LinkButton>
                </div>
                <div className={isMiddleScreen ? "mobile" : ""}>
                  <ColorButton
                    className="save-button"
                    onClick={onUpdatePackageCustomFieldsMapping}
                    disabled={readonly}
                  >
                    Save Changes
                  </ColorButton>
                </div>
              </Panel>
              <Panel
                className="panel-shift panel-info"
                title={goTabFieldsTitle}
              >
                <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
                  <ColorButton
                    className="save-button go-tab-button"
                    onClick={onGetGoTabProductAndSpotList}
                    disabled={readonly || !venue?.goTabLocation}
                  >
                    Get Product and Spot Lists
                  </ColorButton>
                </div>
                <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
                  <FormSelectField
                    label="Product"
                    className="field"
                    options={goTabProductList}
                    selectedKey={goTabProductId}
                    onChange={(
                      _: React.FormEvent<HTMLDivElement>,
                      item?: IDropdownOption
                    ) => setGoTabProductId(item?.key as string)}
                    disabled={readonly || goTabProductList.length === 0}
                  />
                </div>
                <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
                  <FormSelectField
                    label="Spot"
                    className="field"
                    options={goTabSpotList}
                    selectedKey={goTabSpotUuid}
                    onChange={(
                      _: React.FormEvent<HTMLDivElement>,
                      item?: IDropdownOption
                    ) => setGoTabSpotUuid(item?.key as string)}
                    disabled={readonly || goTabSpotList.length === 0}
                  />
                </div>
                <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
                  <ColorButton
                    className="save-button"
                    onClick={onSaveGoTabFields}
                    disabled={readonly || !venue?.goTabLocation}
                  >
                    Save Changes
                  </ColorButton>
                </div>
              </Panel>
              <Panel
                className="panel-shift panel-info"
                title={assignDurationSettingsTitle}
              >
                {newAssignDuration.map((item) => (
                  <div
                    className={`fields-mapping ${isMiddleScreen ? "mobile" : ""
                      }`}
                    key={item.id}
                  >
                    <FormSelectField
                      label="Number of guest"
                      className="field"
                      options={filterGuestOption(item.id)}
                      selectedKey={+item.guests}
                      onChange={(_: any, option) => {
                        onUpdateAssignDuration({
                          ...item,
                          guests: option?.key ? (+option?.key).toString() : "",
                        });
                      }}
                      disabled={readonly || !enableAssignDuration}
                    />
                    <FormSelectField
                      label="Duration in slot"
                      className="field"
                      options={durationOptions}
                      selectedKey={+item.duration}
                      onChange={(_: any, option) => {
                        onUpdateAssignDuration({
                          ...item,
                          duration: option?.key
                            ? (+option?.key).toString()
                            : "",
                        });
                      }}
                      disabled={readonly || !enableAssignDuration}
                    />
                    <IconButton
                      className="delete-button"
                      ariaLabel="Remove duration"
                      onClick={() => onRemoveAssignDuration(item)}
                      disabled={readonly || !enableAssignDuration}
                    >
                      <TrashIcon />
                    </IconButton>
                  </div>
                ))}
                <div className={isMiddleScreen ? "mobile" : ""}>
                  <LinkButton
                    className="add-button"
                    onClick={onAddAssignDuration}
                    disabled={readonly || !enableAssignDuration}
                  >
                    <Icon iconName="Add" className="add" />
                    Add item
                  </LinkButton>
                </div>
                <div className={isMiddleScreen ? "mobile" : ""}>
                  <ColorButton
                    className="save-button"
                    onClick={onSaveAssignDuration}
                    disabled={readonly}
                  >
                    Save Changes
                  </ColorButton>
                </div>
              </Panel>
              <Panel
                className="panel-shift panel-info"
                title={assignDurationForAdminSettingsTitle}
              >
                {newAssignDurationForAdmin.map((item) => (
                  <div
                    className={`fields-mapping ${isMiddleScreen ? "mobile" : ""
                      }`}
                    key={item.id}
                  >
                    <FormSelectField
                      label="Number of guest"
                      className="field"
                      options={filterGuestOption(item.id)}
                      selectedKey={+item.guests}
                      onChange={(_: any, option) => {
                        onUpdateAssignDurationForAdmin({
                          ...item,
                          guests: option?.key ? (+option?.key).toString() : "",
                        });
                      }}
                      disabled={readonly || !enableAssignDuration}
                    />
                    <FormSelectField
                      label="Duration in slot"
                      className="field"
                      options={durationOptions}
                      selectedKey={+item.duration}
                      onChange={(_: any, option) => {
                        onUpdateAssignDurationForAdmin({
                          ...item,
                          duration: option?.key
                            ? (+option?.key).toString()
                            : "",
                        });
                      }}
                      disabled={readonly || !enableAssignDuration}
                    />
                    <IconButton
                      className="delete-button"
                      ariaLabel="Remove duration"
                      onClick={() => onRemoveAssignDurationForAdmin(item)}
                      disabled={readonly || !enableAssignDuration}
                    >
                      <TrashIcon />
                    </IconButton>
                  </div>
                ))}
                <div className={isMiddleScreen ? "mobile" : ""}>
                  <LinkButton
                    className="add-button"
                    onClick={onAddAssignDurationForAdmin}
                    disabled={readonly || !enableAssignDuration}
                  >
                    <Icon iconName="Add" className="add" />
                    Add item
                  </LinkButton>
                </div>
                <div className={isMiddleScreen ? "mobile" : ""}>
                  <ColorButton
                    className="save-button"
                    onClick={onSaveAssignDuration}
                    disabled={readonly}
                  >
                    Save Changes
                  </ColorButton>
                </div>
              </Panel>
              <Panel
                className="panel-shift panel-info"
                title={revenueCategoriesMappingTitle}
              >
                {newRevenueCategoriesMapping.map((item) => (
                  <div
                    className={`fields-mapping ${isMiddleScreen ? "mobile" : ""
                      }`}
                    key={item.id}
                  >
                    <FormSelectField
                      label="Revenue Category"
                      className="field"
                      options={revenueCategoriesOptions}
                      selectedKey={item.category}
                      onChange={(_: any, option) => {
                        onUpdateRevenueCategoriesMapping({
                          ...item,
                          category: option?.key ? option?.key.toString() : "",
                        });
                      }}
                      disabled={readonly}
                    />
                    <FormTextField
                      label="Revenue Percentage"
                      className="field"
                      type="number"
                      value={item.value || "0"}
                      onChange={(_: any, value?: string) =>
                        onUpdateRevenueCategoriesMapping({
                          ...item,
                          value: value || "0",
                        })
                      }
                      autoComplete="off"
                      disabled={readonly}
                    />
                    <IconButton
                      className="delete-button"
                      ariaLabel="Remove Revenue Category"
                      onClick={() => onRemoveRevenueCategoriesMapping(item)}
                    >
                      <TrashIcon />
                    </IconButton>
                  </div>
                ))}
                <div className={isMiddleScreen ? "mobile" : ""}>
                  <LinkButton
                    className="add-button"
                    onClick={onAddRevenueCategoriesMapping}
                  >
                    <Icon iconName="Add" className="add" />
                    Add item
                  </LinkButton>
                </div>
                {errorRevenueCategoriesMapping && (
                  <div className="error">{errorRevenueCategoriesMapping}</div>
                )}
                <div className={isMiddleScreen ? "mobile" : ""}>
                  <ColorButton
                    className="save-button"
                    onClick={onUpdatePackageRevenueCategoriesMapping}
                    disabled={readonly}
                  >
                    Save Changes
                  </ColorButton>
                </div>
              </Panel>
              <Panel className="panel-shift panel-info" title={squareTitle}>
                <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
                  <FormSelectField
                    label="Items"
                    className="field"
                    options={square.catalogs.map((c) => ({
                      key: c.id,
                      text: c.name,
                    }))}
                    selectedKey={squareItemIds}
                    onChange={(_, item) => {
                      if (!item) return;
                      setSquareItemIds([item.key.toString()]);
                    }}
                    disabled={
                      readonly ||
                      !isSquareConnected ||
                      square.catalogs.length === 0
                    }
                  />
                </div>
                <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
                  <ColorButton
                    className="save-button square-button"
                    onClick={onGetSquareItemList}
                    disabled={readonly || !isSquareConnected}
                  >
                    Get Items
                  </ColorButton>
                </div>
                <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
                  <ColorButton
                    className="save-button"
                    onClick={onSaveSquareFields}
                    disabled={readonly || !isSquareConnected}
                  >
                    Save Changes
                  </ColorButton>
                </div>
              </Panel>

              <Panel className={`panel-shift panel-info ${isMiddleScreen ? "mobile" : " "}`} title={packageLanguageTitle}>
                <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
                  <FormTextField
                    label="Reservation Details"
                    value={reservationDetails}
                    onChange={(_: any, value?: string) =>
                      setReservationDetails(value || "")
                    }
                    autoComplete="off"
                    disabled={readonly}
                  />
                </div>
                <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>

                  <FormTextField
                    label="Select Time"
                    value={selectTime}
                    onChange={(_: any, value?: string) =>
                      setSelectTime(value || "")
                    }
                    autoComplete="off"
                    disabled={readonly}
                  />
                </div>

                <div className={`row ${isMiddleScreen ? "mobile" : ""}`}>
                  <ColorButton
                    className="save-button"
                    onClick={onSavePackageLanguage}
                    disabled={readonly}
                  >
                    Save Changes
                  </ColorButton>
                </div>
              </Panel>

              <Panel
                className="panel-shift panel-info"
                title={packagePoliciesTitle}
              >
                <div className="row">
                  <FormTextField
                    label="Package Policies Title"
                    value={packagePolicyTitle}
                    onChange={(_: any, value?: string) =>
                      setPackagePolicyTitle(value || "")
                    }
                    autoComplete="off"
                    disabled={readonly}
                  />
                </div>
                <div className="row">
                  <FormHtmlTextField setValue={setPackagePolicyText} value={packagePolicyText} label='Package Policies' readOnly={readonly} />
                </div>
                <div className="row">
                  <Toggle
                    label="Guest must agree to Package Policies"
                    className="row poilcy-toggle"
                    checked={showPolicy}
                    onChange={(_: any, value?: boolean) => setShowPolicy(value || false)}
                    inlineLabel
                    disabled={readonly}
                  />
                </div>
                <div className={isMiddleScreen ? "mobile" : ""}>
                  <ColorButton
                    className="save-button"
                    onClick={onSavePackagePolicies}
                    disabled={readonly}
                  >
                    Save Changes
                  </ColorButton>
                </div>
              </Panel>
            </div>
            <div className={`right-panel ${isMiddleScreen ? "mobile" : ""}`}>
              {!isMiddleScreen && (
                <Panel
                  className="panel-shift panel-for-controls"
                  title={packageControlsTitle}
                >
                  <div className="toggles-container">
                    <div className="toggle-active-inactive body2">
                      <Toggle
                        checked={!!currentPackage?.status}
                        onChange={onChangeActivePackage}
                      />
                      <div className="label">
                        {!!currentPackage?.status
                          ? "Package is Active"
                          : "Package is Inactive"}
                      </div>
                    </div>
                    <div className="toggle-active-inactive body2">
                      <Toggle
                        checked={!!currentPackage?.adminOnly}
                        onChange={onChangeAdminOnlyPackage}
                      />
                      <div className="label">Package is Admin only</div>
                    </div>
                    <div className="toggle-active-inactive body2">
                      <Toggle
                        checked={!!currentPackage?.isHidden}
                        onChange={onChangeIsHiddenPackage}
                      />
                      <div className="label">Package is Hidden</div>
                    </div>
                    <div className="toggle-active-inactive body2">
                      <Toggle
                        checked={!!currentPackage?.contactOnly}
                        onChange={onChangeContactOnlyPackage}
                      />
                      <div className="label">Package is Contact only</div>
                    </div>
                    {venue?.enableSteltronic && (
                      <div className="toggle-active-inactive body2">
                        <Toggle
                          checked={!!currentPackage?.syncWithSteltronic}
                          onChange={onChangeSyncWithSteltronicPackage}
                        />
                        <div className="label">Sync With Steltronic</div>
                      </div>
                    )}
                    <div className="toggle-active-inactive body2">
                      <Toggle
                        checked={!!currentPackage?.hidePackageDuration}
                        onChange={onChangeHidePackageDuration}
                      />
                      <div className="label">Hide Package Duration</div>
                    </div>
                    <div className="toggle-active-inactive body2">
                      <Toggle
                        checked={!!currentPackage?.hideInquiryRedirect}
                        onChange={onChangeHideInquiryRedirect}
                      />
                      <div className="label">Hide Inquiry redirect</div>
                    </div>
                    <div className="toggle-active-inactive body2">
                      <Toggle
                        checked={!!currentPackage?.hidePrice}
                        onChange={onChangeHidePrice}
                      />
                      <div className="label">Hide Price</div>
                    </div>
                    <div className="toggle-active-inactive body2">
                      <Toggle
                        checked={!!currentPackage?.hidePriceOnTimeCard}
                        onChange={onChangeHidePriceOnTimeCard}
                      />
                      <div className="label">Hide Price on Time Card</div>
                    </div>

                    {venue?.enableAboutGolfIntegration && (
                      <div className="toggle-active-inactive body2">
                        <Toggle
                          checked={!!currentPackage?.enableAboutGolfIntegration}
                          onChange={onChangeEnableAboutGolfIntegration}
                        />
                        <div className="label">Enable About Golf</div>
                      </div>
                    )}
                    {venue?.enableGoTabIntegration && (
                      <div className="toggle-active-inactive body2">
                        <Toggle
                          checked={!!currentPackage?.enableGoTabIntegration}
                          onChange={onChangeEnableGotTabIntegration}
                        />
                        <div className="label">Sync with GoTab</div>
                      </div>
                    )}
                    {!currentPackage?.contactOnly && (
                      <div className="body2">
                        <FormSelectField
                          label="Parent Schedule"
                          options={parentScheduleList}
                          selectedKey={
                            currentPackage?.parentSchedule ||
                            parentScheduleList[0].key
                          }
                          onChange={(
                            _: React.FormEvent<HTMLDivElement>,
                            item?: IDropdownOption
                          ) => onChangeParentSchedule(item?.key as string)}
                          disabled={readonly}
                        />
                      </div>
                    )}
                  </div>
                  {!currentPackage?.contactOnly && (
                    <>
                      <div className="splitter"></div>
                      <DefaultButton
                        className="actions-button"
                        onClick={onViewPackage}
                      >
                        <OpenIcon />
                        <div className="actions-text">View Package</div>
                      </DefaultButton>
                      <DefaultButton
                        className="actions-button"
                        onClick={() => setIsOpenCurrentPackageSchedule(true)}
                      >
                        <CalendarIcon />
                        <div className="actions-text">
                          View Package Calendar
                        </div>
                      </DefaultButton>
                    </>
                  )}
                </Panel>
              )}
              <Panel
                className="panel-shift"
                title={
                  <>
                    <div className="h6">Reservation Taxes</div>
                    <LinkButton
                      onClick={() =>
                        setIsOpenReservationTaxes(!isOpenReservationTaxes)
                      }
                    >
                      Edit
                    </LinkButton>
                  </>
                }
              >
                {isOpenReservationTaxes ? (
                  <div
                    className={`tax-selector-container ${isMiddleScreen ? "mobile" : ""
                      }`}
                  >
                    <Checkbox
                      key="noTax"
                      className="tax-selector"
                      label="No Tax"
                      checked={reservationTaxes === ReservationTaxType.noTax}
                      onChange={(_: any, checked?: boolean) =>
                        onSelectReservationTax(
                          ReservationTaxType.noTax,
                          !!checked
                        )
                      }
                      disabled={
                        readonly ||
                        reservationTaxes
                          .split(";")
                          .includes(ReservationTaxType.venueTax) ||
                        reservationTaxes
                          .split(";")
                          .some((item) =>
                            venue?.taxes?.map((t) => t.name).includes(item)
                          )
                      }
                    />
                    <Checkbox
                      key="venueTax"
                      className="tax-selector"
                      label={`${ReservationTaxType.venueTax} ${venue?.tax ? `(${venue.tax}%)` : "(0%)"
                        }`}
                      checked={reservationTaxes
                        .split(";")
                        .includes(ReservationTaxType.venueTax)}
                      onChange={(_: any, checked?: boolean) =>
                        onSelectReservationTax(
                          ReservationTaxType.venueTax,
                          !!checked
                        )
                      }
                      disabled={
                        readonly ||
                        reservationTaxes === ReservationTaxType.noTax
                      }
                    />
                    {reservationTaxSelectors}
                    <div className={isMiddleScreen ? "mobile" : ""}>
                      <ColorButton
                        className="save-button"
                        onClick={onUpdateReservationTaxes}
                        disabled={readonly}
                      >
                        Save Changes
                      </ColorButton>
                    </div>
                  </div>
                ) : (
                  <div className="tax-selector-container detail-title">
                    {getReservationTaxesList()}
                  </div>
                )}
              </Panel>
              <Panel className="panel-shift" title={imageTitle}>
                <div
                  className={`image-fields-container ${isMiddleScreen ? "mobile" : ""
                    }`}
                >
                  <div className="image-field">
                    <div className="detail-title">Image</div>
                    <Image
                      image={currentPackage?.image}
                      loading={isImageLoadInProgress}
                      uploadImage={uploadPackageImageFile}
                      subTitle="400x400 pixels"
                    />
                  </div>
                </div>
              </Panel>
              <Panel className="panel-shift" title={colorTitle}>
                <div
                  className={`color-fields-container ${isMiddleScreen ? "mobile" : ""
                    }`}
                >
                  <FormSelectField
                    label="Card Color"
                    className="field color"
                    options={cardColorTypeOptions}
                    selectedKey={cardColor}
                    onRenderOption={onRenderOption}
                    onRenderTitle={onRenderTitle}
                    onChange={(_: any, option) => {
                      setCardColor(
                        option?.key
                          ? option?.key.toString()
                          : DEFAULT_CARD_COLOR
                      );
                    }}
                    disabled={readonly}
                  />
                  <div className={isMiddleScreen ? "mobile" : ""}>
                    <ColorButton
                      className="save-color-button"
                      onClick={onUpdateCardColor}
                      disabled={readonly}
                    >
                      Save Changes
                    </ColorButton>
                  </div>
                </div>
              </Panel>
            </div>
          </div>
        </div>
      </div>
      {isOpenCurrentPackageSchedule && (
        <CurrentSchedule
          onClose={() => setIsOpenCurrentPackageSchedule(false)}
          currentPackage={currentPackage}
          isLoadingPackage={isLoadingPackage}
          currentPackageSchedule={currentPackageSchedule}
        />
      )}
      {isRemoveOpen && (
        <DeleteConfirmation
          ConfirmationHeader={"Confirmation"}
          ConfirmationText={"Do you want to remove this schedule?"}
          ConfirmationButtonText={"Delete Schedule"}
          onClose={confirmedRemovePackageSchedule}
        />
      )}
      {isOpenScheduleEdit && (
        <EditSchedulePackage
          onClose={() => setIsOpenScheduleEdit(false)}
          schedulePackage={schedulePackage}
          timeShifting={timeSlotShifting || []}
          isPackageSchedule={
            currentPackage?.parentSchedule === currentPackage?.id
          }
          parentScheduleParams={parentScheduleParams}
          ageForPolicy={venue?.ageForPolicy || "21"}
          packageMethod={currentPackage?.method}
        />
      )}
      {isOpenEdit && (
        <EditPackage
          isClonePackage={false}
          onClose={() => setIsOpenEdit(false)}
        />
      )}
      {isOpenMembershipsSettings && (
        <EditMembershipsSettings
          onClose={() => setIsOpenMembershipSettings(false)}
        />
      )}
      {isOpenEdit && (
        <EditPackage
          isClonePackage={isClonePackage}
          onClose={() => setIsOpenEdit(false)}
        />
      )}
      {isOpenEditTripleSeatSettings && (
        <EditTripleSeatForPackage
          onClose={() => setIsOpenEditTripleSeatSettings(false)}
        />
      )}
      {isOpenEditSeatNinjaSettings && (
        <EditSeatNinjaForPackage
          onClose={() => setIsOpenEditSeatNinjaSettings(false)}
        />
      )}
      {isOpenEditPackageCancellation && (
        <EditPackageCancellation
          onClose={() => setIsOpenEditPackageCancellation(false)}
        />
      )}
      {isOpenEditAdminPackageReservation && (
        <EditAdminPackageReservation
          onClose={() => setIsOpenEditAdminPackageReservation(false)}
        />
      )}
    </div>
  );
};

export default connector(PackageDetails);
